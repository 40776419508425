<div *ngIf="loading" class="flex justify-center items-center h-screen">
  <div class="spinner"></div>
</div>
<div class="flex flex-col items-center min-h-screen w-full">
  <div *ngIf="!loading" class="w-full lg:max-w-5xl xl:max-w-7xl py-3 px-5">
    <div class="md:flex gap-4 mb-3 items-center">
      <input
        type="text"
        (input)="onSearchChange($event)"
        placeholder="Search"
        class="p-2 border border-gray-300 rounded-md"
      />
      <select
        (change)="sortProduct($event)"
        class="p-2 border border-gray-300 rounded-md"
      >
        <option value="priceLowHigh">Thấp đến cao</option>
        <option value="priceHighLow">Cao đến thấp</option>
      </select>
    </div>
    <div class="border border-red-900 mb-3"></div>
    <mat-paginator
      class="mb-2"
      [length]="totalProducts"
      [pageSize]="pageSize"
      [pageIndex]="currentPage"
      [pageSizeOptions]="[4, 8, 10]"
      (page)="onPageChange($event)"
    ></mat-paginator>
    <div class="flex items-center mb-4">
      <h2 class="text-xl font-semibold">Có thể ban sẽ thích</h2>
      <a href="#" class="text-blue-600 hover:underline ml-4">Tất cả</a>
    </div>
    <div
      class="grid sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 gap-7 max-sm:px-4 max-sm:py-2"
    >
      <div
        *ngFor="let item of filteredProducts"
        class="border rounded-lg overflow-hidden shadow-lg transition-transform transform hover:scale-105 cursor-pointer"
        [routerLink]="['/listing', sanitizeName(item.title) + '-' + item.id]"
      >
        <div class="flex items-center space-x-4 p-[3px] mt-2 ml-1">
          <div
            class="w-12 h-12 bg-cover bg-center rounded-full"
            [ngStyle]="{ 'background-image': 'url(' + item.avatar + ')' }"
          ></div>
          <div>
            <h2 class="text-lg font-semibold">
              {{ splitEmail(item.userName) }}
            </h2>
            <p class="text-gray-600">{{ item.price | currency : "VND" }}</p>
          </div>
        </div>
        <div>
          <div class="h-64 flex justify-center items-center">
            <img
              class="h-60 w-full object-cover"
              src="{{ findFirstImageUrl(item.filesPathUrl) }}"
            />
          </div>
          <p class="text-gray-700 p-2 pt-1 font-medium pb-4">
            {{ item.title | truncate : 80 }}
          </p>
        </div>
      </div>
    </div>
    <div class="flex items-center mt-12 mb-4">
      <h2 class="text-xl font-semibold">Free products</h2>
      <a href="#" class="text-blue-600 hover:underline ml-4">Tất cả</a>
    </div>
    <div
      class="grid sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 gap-7 max-sm:px-4 max-sm:py-2"
    >
      <div
        *ngFor="let item of zeroPriceProducts"
        class="border rounded-lg overflow-hidden shadow-lg transition-transform transform hover:scale-105 cursor-pointer"
        [routerLink]="['/listing', sanitizeName(item.title) + '-' + item.id]"
      >
        <div class="flex items-center space-x-4 p-[3px] mt-2 ml-1">
          <div
            class="w-12 h-12 bg-cover bg-center rounded-full"
            [ngStyle]="{ 'background-image': 'url(' + item.avatar + ')' }"
          ></div>
          <div>
            <h2 class="text-lg font-semibold">
              {{ splitEmail(item.userName) }}
            </h2>
            <p class="text-gray-600">{{ item.price | currency : "VND" }}</p>
          </div>
        </div>
        <div>
          <div class="h-64 flex justify-center items-center">
            <img
              class="h-60 w-full object-cover"
              src="{{ findFirstImageUrl(item.filesPathUrl) }}"
            />
          </div>
          <p class="text-gray-700 p-2 pt-1 font-medium pb-4">
            {{ item.title | truncate : 80 }}
          </p>
        </div>
      </div>
    </div>
  </div>
</div>

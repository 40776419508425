import { Component, HostListener } from '@angular/core';

@Component({
  selector: 'app-category-bar',
  templateUrl: './category-bar.component.html',
  styleUrls: ['./category-bar.component.css'],
})
export class CategoryBarComponent {
  categories = [
    { icon: 'fas fa-female', label: 'Phụ nữ' },
    { icon: 'fas fa-male', label: 'Đàn ông' },
    { icon: 'fas fa-mobile-alt', label: 'Thiết bị điện tử' },
    { icon: 'fas fa-robot', label: 'Đồ chơi trẻ em' },
    { icon: 'fas fa-gamepad', label: 'Gaming' },
    { icon: 'fas fa-handbag', label: 'Túi xách' },
    //{ icon: 'fas fa-home', label: 'Home' },
    { icon: 'fas fa-clock', label: 'Cổ điển' },
    { icon: 'fas fa-spa', label: 'Làm đẹp' },
    { icon: 'fas fa-baby', label: 'Trẻ em' },
    { icon: 'fas fa-football-ball', label: 'Thể thao' },
    { icon: 'fas fa-handshake', label: 'Thủ công' },
    { icon: 'fas fa-briefcase', label: 'Văn phòng' },
    { icon: 'fas fa-paw', label: 'Thú cưng' },
  ];
  visibleItemsCount!: number;
  constructor() {
    this.updateVisibleItemsCount(window.innerWidth);
  }
  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    this.updateVisibleItemsCount(event.target.innerWidth);
  }

  updateVisibleItemsCount(windowWidth: number) {
    if (windowWidth < 640) {
      this.visibleItemsCount = 5;
    } else if (windowWidth < 768) {
      this.visibleItemsCount = 9;
    } else {
      this.visibleItemsCount = this.categories.length;
    }
  }
}

<div
  *ngIf="product"
  class="flex flex-wrap justify-center mx-auto p-3 max-w-[1400px] bg-white shadow-lg"
>
  <div class="w-full md:w-1/2 mb-5 relative overflow-hidden flex flex-row">
    <div
      class="flex flex-col max-lg:hidden flex-[1] overflow-y-auto md:max-[500px] border border-gray-300"
    >
      <ng-container *ngFor="let file of product.filesPathUrl; let i = index">
        <img
          *ngIf="isImage(file)"
          [src]="file"
          [alt]="'Product File ' + (i + 1)"
          (mouseenter)="changeMainFile(i)"
          [class.active]="i === currentFileIndex"
          class="w-[100px] h-auto mb-2 cursor-pointer h border-2 border-transparent"
        />
        <video
          *ngIf="isVideo(file)"
          (mouseenter)="changeMainFile(i)"
          [class.active]="i === currentFileIndex"
          muted
          class="w-[100px] h-auto mb-2 cursor-pointer"
        >
          <source [src]="file" type="video/mp4" />
        </video>
      </ng-container>
    </div>
    <div
      class="relative flex-[5] items-center justify-center min-h-[300px]"
      (touchstart)="handleTouchStart($event)"
      (touchmove)="handleTouchMove($event)"
      (touchend)="handleTouchEnd()"
    >
      <div
        *ngIf="isImage(product.filesPathUrl[currentFileIndex])"
        class="flex items-center justify-center"
      >
        <img
          class="w-full max-w-[500px]"
          [src]="product.filesPathUrl[currentFileIndex]"
          alt="Main Product File"
        />
      </div>
      <div
        *ngIf="isVideo(product.filesPathUrl[currentFileIndex])"
        class="flex items-center justify-center w-full h-full"
      >
        <video controls autoplay class="w-full max-w-[500px]" [volume]="0.3">
          <source
            [src]="product.filesPathUrl[currentFileIndex]"
            type="video/mp4"
          />
          Your browser does not support the video tag.
        </video>
      </div>
      <div
        class="absolute top-1/2 w-full flex justify-between transform -translate-y-[1/2] max-md:hidden"
      >
        <button
          (click)="previousFile()"
          class="bg-none border-none cursor-pointer"
        >
          <img
            style="width: 40px"
            src="assets/icons/arrow-left-circle-fill.svg"
          />
        </button>
        <button (click)="nextFile()" class="bg-none border-none cursor-pointer">
          <img
            style="width: 40px"
            src="assets/icons/arrow-right-circle-fill.svg"
          />
        </button>
      </div>
      <div
        class="absolute bottom-0 w-full flex justify-center py-2 bg-black bg-opacity-50 md:hidden"
      >
        <div class="flex space-x-2">
          <div>
            <span class="text-white font-semibold"
              >{{ currentFileIndex + 1 }} /
              {{ product.filesPathUrl.length }}</span
            >
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="w-full md:w-[40%] md:pl-5 overflow-hidden">
    <div class="flex items-center mb-3">
      <img
        [src]="product.avatar"
        alt="{{ product.userName }}"
        class="w-[50px] h-[50px] rounded-full mr-5"
      />
      <div>
        <div class="flex items-center text-[#4caf50] text-sm mt-1">
          <h1 class="text-xl">{{ product.userName }}</h1>
          <img
            src="assets/icons/icn-verified.svg"
            alt="Profile verified"
            class="w-[20px] h-[20px] mr-1"
          />
          Đã xác minh
        </div>
        <p class="text-gray-600">{{ product.userRoles.join(", ") }}</p>
        <div class="flex gap-2 text-sm text-gray-600">
          <span>⭐⭐⭐⭐⭐ 8 đánh giá</span>
          <span>20 bài đăng</span>
          <span>10 đã bán</span>
        </div>
      </div>
    </div>
    <div class="ml-5">
      <h1 class="font-bold text-xl">{{ product.title }}</h1>
      <div class="flex items-center mb-3">
        <span class="text-lg font-semibold text-gray-600 mx-2"
          >{{ product.price | number : "1.0-0" }} VND</span
        >
        <!-- <span class="text-lg text-[#4caf50]">Free shipping</span> -->
      </div>
      <div class="flex flex-auto gap-2 mb-3 space-x-4">
        <button
          class="h-10 px-5 font-semibold rounded-md bg-black text-white hover:bg-gray-400 hover:border-gray-400"
        >
          PhoneNumber Seller
        </button>
        <button
          (click)="
            chatWithSeller(product.userId, product.avatar, product.userName)
          "
          class="h-10 px-6 font-semibold rounded-md border border-slate-200 text-slate-900 transition hover:bg-gray-400 hover:border-gray-400"
        >
          Chat with Seller
        </button>
      </div>
      <div class="pt-2 rounded-lg">
        <h3 class="font-semibold text-2xl mb-4">Details</h3>
        <p class="mb-2"><strong>Condition:</strong> new</p>
        <p class="mb-2"><strong>State:</strong> {{ product.state }}</p>
        <p class="mb-2"><strong>Category:</strong> {{ product.category }}</p>
        <p class="mb-2">
          <strong>Posted:</strong>
          {{ product.createdDate | date : "dd/MM/yyyy" }}
        </p>
        <p class="mb-2">
          <strong>Description:</strong> {{ product.description }}
        </p>
        <p class="mb-2"><strong>Delivery From:</strong> Việt Nam</p>
        <p><strong>Shipping:</strong> 23,000 Đ</p>
      </div>
    </div>
  </div>
</div>

import { NgModule, ErrorHandler } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule } from '@angular/common/http';

import { AppRoutingModule } from './app-routing.module';

import { ConfigurationService } from './services/configuration.service';
import { LocalStoreManager } from './services/local-store-manager.service';
import { AppComponent } from './app.component';
import { HomeComponent } from './components/home/home.component';
import { SettingsComponent } from './components/settings/settings.component';
import { NotFoundComponent } from './components/not-found/not-found.component';

import { MatFormFieldModule } from '@angular/material/form-field';
import { UserProfileModule } from './components/user-profile/user-profile.module';
import { ProductModule } from './components/product/product.module';
import { MatSelectModule } from '@angular/material/select';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatInputModule } from '@angular/material/input';
import { CheckoutModule } from './components/checkout/checkout.module';
import { PostManagementModule } from './components/post-management/post-management.module';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { AdminNotificationComponent } from './components/notification/admin-notification/admin-notification.component';
import { FormsModule } from '@angular/forms';
import { ChatModule } from './components/chat/chat.module';
import { FooterComponent } from './footer/footer.component';
import { NavBarModule } from './nav-bar/nav-bar.module';
import { RouterModule } from '@angular/router';
import { CartModule } from './components/cart/cart.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatDialogModule } from '@angular/material/dialog';
import { VideoChatComponent } from './components/chat/video-chat/video-chat.component';
import { CategoryBarComponent } from './components/home/category-bar/category-bar.component';
import { AdminDashboardComponent } from './page/admin-dashboard/admin-dashboard.component';
@NgModule({
  declarations: [
    AppComponent,
    NotFoundComponent,
    HomeComponent,
    SettingsComponent,
    AdminNotificationComponent,
    FooterComponent,
    CategoryBarComponent,
    AdminDashboardComponent,
  ],
  imports: [
    NavBarModule,
    ChatModule,
    FormsModule,
    RouterModule,
    MatSelectModule,
    MatProgressSpinnerModule,
    BrowserModule,
    CheckoutModule,
    BrowserAnimationsModule,
    MatProgressSpinnerModule,
    HttpClientModule,
    AppRoutingModule,
    UserProfileModule,
    MatFormFieldModule,
    ProductModule,
    MatSnackBarModule,
    MatDialogModule,
    MatInputModule,
    PostManagementModule,
    CartModule,
  ],
  providers: [ConfigurationService, LocalStoreManager, VideoChatComponent],
  bootstrap: [AppComponent],
})
export class AppModule {}
